.brand {
	position: relative;
	width: 260px;
	height: auto;
	margin-top: 15px;
	margin-right: 10px;
	margin-left: 10px;
	padding-right: 10px;
	padding-left: 10px;
	float: none;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	cursor: pointer;
}

.company-logo.hirsh-brand {
	opacity: 0.5;
}

.company-logo.mexico {
	opacity: 1;
}

.nav-menu-wrap {
	margin-top: -18px;
}

.section-header-on-white {
	margin-bottom: 2rem;
}

@media (min-width: 992px) {
	.accesible-navigation-menu ul {
		margin-top: -7px;
	}

	div.utility-nav {
		top: -18px;
	}

	.header-section.sticky div.utility-nav {
		top: -22px;
	}

	.header-section.sticky .nav-menu-wrap {
		margin-top: -22px;
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	div.utility-nav {
		top: -8px;
	}
}

@media (max-width: 767px) and (min-width: 478px) {
	div.utility-nav {
		top: -3px;
	}

	.brand,
	.brand.w--current {
		width: 240px;
		margin-top: -9px;
	}
}

@media (max-width: 991px) {
	.parts-store-container {
		background-position: right bottom;
	}
}

@media (max-width: 479px) {
	.brand,
	.brand.w--current {
		display: inline-block;
		width: 200px;
		margin-top: -7px;
	}
}

@media (max-width: 479px) {
	.grid-container {
		padding-top: 70px;
	}
}

@media (max-width: 324px) {
	.grid-container {
		padding-top: 113px;
	}
}
