.w-embed-youtubevideo {
	width: 100%;
	position: relative;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	background-size: cover;
	background-position: 50% 50%;
}

.w-embed-youtubevideo:empty {
	min-height: 75px;
	padding-bottom: 56.25%;
}

.header-top {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1700px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dropdown-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.brand {
	position: relative;
	width: 314px;
	margin-top: 1.5rem;
	margin-right: 10px;
	margin-left: 10px;
	padding-right: 10px;
	padding-left: 10px;
	float: none;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	cursor: pointer;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	float: none;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-top: 1px none rgba(121, 118, 118, 0.5);
	border-bottom: 1px none rgba(121, 118, 118, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
}

.header-search-form {
	display: block;
	height: 100px;
	border-style: none;
}

.close-link {
	margin-bottom: 0.75rem;
	padding: 0.25rem 0.25rem 0.25rem 0.5rem;
	float: right;
	text-decoration: none;
}

.header-flex-container {
	position: relative;
	display: block;
	max-width: 1424px;
	margin-right: auto;
	margin-bottom: 0rem;
	margin-left: auto;
	padding: 0.15rem 0% 0rem 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-image: none;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.dropdown-link {
	position: relative;
	display: block;
	margin-top: 0rem;
	padding: 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	font-size: 0.98rem;
	line-height: 1.4rem;
	letter-spacing: 0rem;
	text-decoration: none;
}

.dropdown-link:hover {
	color: #ed1c29;
}

.dropdown-link.w--current {
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/arrow-medium.svg');
	background-position: 0px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	color: #d8263c;
}

.dropdown-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.dd-link-wrap {
	margin-bottom: 5px;
	border-bottom: 1px none rgba(121, 118, 118, 0.5);
	background-color: transparent;
	text-align: center;
	cursor: pointer;
}

.utility-nav {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 55px;
	padding-top: 12px;
	padding-left: 0.75rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
}

.header-search-field {
	display: inline-block;
	width: 70%;
	height: 2.5rem;
	margin-bottom: 0px;
	float: left;
	border: 0px none transparent;
}

.search-form {
	height: 36px;
	border-bottom: 1px solid rgba(121, 118, 118, 0.5);
}

.navbar {
	display: block;
	max-width: 1424px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.search-text {
	width: 120px;
	height: 36px;
	margin-bottom: 0px;
	padding: 6px 5px 0px;
	float: left;
	border-style: none;
	border-bottom-width: 1px;
	border-bottom-color: #000;
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Montserrat, sans-serif;
	color: #2e2e2e;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
}

.search-text:focus {
	width: 170px;
	padding-left: 10px;
	border-bottom-style: none;
	color: #797676;
	text-decoration: none;
}

.search-text::-webkit-input-placeholder {
	font-family: Montserrat, sans-serif;
	color: #797676;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-transform: uppercase;
}

.search-text:-ms-input-placeholder {
	font-family: Montserrat, sans-serif;
	color: #797676;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-transform: uppercase;
}

.search-text::-ms-input-placeholder {
	font-family: Montserrat, sans-serif;
	color: #797676;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-transform: uppercase;
}

.search-text::placeholder {
	font-family: Montserrat, sans-serif;
	color: #797676;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-transform: uppercase;
}

.search-wrapper {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px none #fff;
	border-radius: 24px;
}

.nav-menu-wrap {
	position: relative;
	display: block;
	width: 100%;
	max-width: none;
	margin-right: auto;
	margin-bottom: 0rem;
	margin-left: auto;
	padding-right: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-bottom: 1px none rgba(121, 118, 118, 0.5);
}

.arrow-icon {
	display: block;
	margin-right: 1rem;
	margin-left: 1rem;
	font-size: 1.5rem;
}

.header-section {
	position: fixed;
	z-index: 999;
	width: 100%;
	padding-right: 5%;
	padding-left: 5%;
	background-color: #fff;
	box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.5);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	height: 124px;
}

.mobile-menu-search-wrap {
	display: none;
}

.search-dd-button {
	display: none;
	width: 3rem;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.75rem 1rem;
	background-image: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.desktop-search-button {
	width: 20px;
	height: 36px;
	padding: 1rem 1.3rem;
	float: right;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.55);
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Blue.svg');
	background-position: 50% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.desktop-search-button:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Red.svg');
	background-size: 18px 18px;
	opacity: 1;
}

.desktop-search-button.account-icon {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Account-Blue.svg');
	background-position: 50% 50%;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
}

.desktop-search-button.account-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Account-Blue.svg');
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.desktop-search-button.cart-icon {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Cart-Blue.svg');
	background-position: 50% 50%;
}

.desktop-search-button.cart-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Cart-Blue.svg');
}

.desktop-search-button.search-icon {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	transition: all 0.2s ease 0s, transform 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	transform-style: preserve-3d;
	transform: translateX(0px) translateY(0px) translateZ(0px);
}

.desktop-search-button.search-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Blue.svg');
	transition: all 0.2s ease 0s, transform 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	transform-style: preserve-3d;
	transform: translateX(0px) translateY(-5px) translateZ(0px);
}

.header-search-form-wrapper {
	display: block;
	width: 50%;
	height: 100px;
	margin-bottom: 0px;
	padding: 1rem;
	float: right;
	background-color: rgba(0, 0, 0, 0.5);
}

.header-search-form-wrapper.search-top-wrapper {
	display: block;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	float: none;
}

.button {
	position: static;
	display: inline-block;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1.5rem;
	padding: 0.5625rem 1rem;
	border: 1px solid #d8263c;
	border-radius: 2px;
	background-color: #d8263c;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
}

.button:hover {
	border-color: #ad1e30;
	background-color: #ad1e30;
}

.button.search-button {
	width: 30%;
	height: 2.5rem;
	min-height: 2.5rem;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 0.38rem 1rem;
	float: left;
}

.main-nav-link {
	position: relative;
	z-index: 900;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0.5rem 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.98rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.main-nav-link:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #ed1c29;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.dd-list {
	position: absolute;
	left: auto;
	right: auto;
	z-index: 899;
	display: block;
	height: 0px;
	max-width: 400px;
	min-width: 200px;
	margin-top: 35px;
	padding: 0.5rem 0.5rem 0.25rem;
	border-style: none;
	border-width: 1px;
	border-color: rgba(121, 118, 118, 0.5);
	background-color: #fff;
	box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.5);
	opacity: 0;
	font-family: Poppins, sans-serif;
	text-align: left;
}

.hidden-search {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	display: none;
	width: 100%;
	height: 100%;
	padding-top: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	transition: opacity 350ms ease-out-cubic 0;
}

.search-field-label {
	margin-bottom: 0.5rem;
	color: #fff;
	font-size: 2rem;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.form-block {
	display: block;
	width: 100%;
	margin-bottom: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.search-exit-link {
	position: relative;
	width: 55px;
	height: 55px;
	min-width: 55px;
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/X-Blue.svg');
	background-position: 50% 50%;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
	margin-bottom: 15px;
	cursor: pointer;
}

.search-exit-link:hover {
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/X-Red.svg');
}

.search-go-button {
	width: 55px;
	height: 55px;
	min-width: 55px;
	margin-left: 0.5rem;
	padding: 0.35rem;
	border-radius: 0rem;
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Next-Arrow-Blue.svg');
	background-position: 50% 50%;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.search-go-button:hover {
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Next-Arrow-Red.svg');
}

.large-search-hidden-div {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1001;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	margin-top: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	opacity: 1;
}

.search-bar-button-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.search-bar-div {
	position: static;
	left: 0%;
	top: 0%;
	right: auto;
	bottom: auto;
	display: block;
	width: 100%;
	padding: 2rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.15);
	background-image: none;
	box-shadow: none;
}

.search-form-hidden {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.exit-button {
	position: static;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 999;
	width: 84px;
	height: 84px;
	margin-top: 0px;
	background-color: transparent;
	opacity: 1;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.exit-button:hover {
	background-color: transparent;
	opacity: 1;
}

.search-bar-field {
	width: 100%;
	height: 4rem;
	margin-bottom: 0px;
	margin-left: 0rem;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-style: solid;
	border-width: 1px;
	border-color: #e9e7e7;
	border-radius: 0.5rem;
	background-color: #fff;
	opacity: 1;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #747373;
}

.search-bar-field:focus {
	background-color: hsla(0, 0%, 100%, 0.45);
}

.search-bar-field::-webkit-input-placeholder {
	color: #fff;
}

.search-bar-field:-ms-input-placeholder {
	color: #fff;
}

.search-bar-field::-ms-input-placeholder {
	color: #fff;
}

.search-bar-field::placeholder {
	color: #fff;
}

.fullscreen-close-search-btn {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: block;
	width: 100%;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: hsla(0, 0%, 100%, 0.9);
	opacity: 1;
}

.red-menu-diamond {
	display: block;
	width: 10px;
	height: 10px;
	margin-right: 1.25rem;
	margin-left: 1.25rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	background-image: url('/20170713122722/assets/images/Diamond-Red.svg');
	background-position: 50% 50%;
	background-size: 8px;
	background-repeat: no-repeat;
}

.search-input {
	width: 100%;
	height: 4rem;
	float: left;
	border-radius: 10px;
	box-shadow: 0 8px 20px -20px #000;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	text-align: center;
}

.search {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hidden-search-holder {
	position: fixed;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	align-self: auto;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.section-2 {
	height: 3000px;
	background-color: #c78c8c;
}

.hidden-search-close-bg {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-color: hsla(0, 0%, 100%, 0.9);
}

.section-3 {
	display: block;
	width: 100%;
}

.slider-section {
	padding-top: 140px;
}

.slider-section-2 {
	height: auto;
	box-shadow: inset 0 5px 9px 0 rgba(0, 0, 0, 0.07);
}

.main-slider-arrow-icon-2 {
	font-size: 1.75rem;
}

.slide {
	background-color: transparent;
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
}

.slide-1 {
	background-position: 50% 60%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-nav {
	display: none;
	padding: 2.5rem 2rem 1.5rem 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	font-size: 0.5rem;
	line-height: 1.5rem;
}

.slider-header-emphasis {
	font-size: 2rem;
	font-weight: 700;
}

.slider-paragraph {
	max-width: 327px;
	margin-right: 1rem;
	margin-left: 0rem;
	padding: 0.5rem 0.75rem 0.5rem 0rem;
	float: left;
	clear: left;
	border-right: 1px solid #fff;
	border-left: 1px none #fff;
	background-color: transparent;
	color: #fff;
	text-align: right;
	margin-top: 10px;
	margin-bottom: 10px;
}

.slider-paragraph p {
	margin-bottom: 0;
}

.slider-header {
	display: inline-block;
	margin-top: 1px;
	margin-bottom: 5px;
	padding: 0.25rem 0.75rem 0rem 1.25rem;
	float: left;
	clear: left;
	background-color: rgba(0, 0, 0, 0.7);
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 2rem;
	line-height: 2.65rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.slider-content-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-decoration: none;
}

.slide-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	background-color: transparent;
	text-decoration: none;
}

.slider-link-block {
	display: block;
	width: 100%;
	height: 100%;
	padding-top: 20rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	text-decoration: none;
}

.slide-2 {
	background-position: 50% 55%;
	background-size: 100%;
	background-repeat: no-repeat;
}

.slider-div {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

.slider-widget {
	display: block;
	height: 650px;
	margin-right: auto;
	margin-left: auto;
	background-color: hsla(0, 0%, 100%, 0);
}

.slider-arrow {
	left: auto;
	top: 48%;
	right: 0%;
	bottom: auto;
	display: block;
	width: 45px;
	height: 45px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding-bottom: 0px;
	border-radius: 0rem;
	background-color: transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	font-size: 2rem;
}

.slider-arrow:hover {
	border-radius: 0rem;
	background-color: #ed1c29;
}

.slider-arrow.left {
	left: 0%;
	top: 48%;
	right: auto;
	bottom: auto;
	display: block;
	width: 45px;
	height: 45px;
	margin-right: 0rem;
	margin-bottom: 1rem;
	margin-left: 1rem;
	background-color: transparent;
}

.slider-arrow.left:hover {
	background-color: #ed1c29;
}

.main-slider-arrow-icon {
	font-size: 1.75rem;
}

.featured-products {
	height: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.div-block {
	position: absolute;
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	width: 400px;
	height: 200px;
	background-color: #fff;
	-webkit-transform: translate(176px, 81px) rotate(-31deg);
	-ms-transform: translate(176px, 81px) rotate(-31deg);
	transform: translate(176px, 81px) rotate(-31deg);
}

.caption-and-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0px 26px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	min-width: 300px;
	min-height: 80px;
}

.slideshow-button-holder {
	margin-bottom: 7px;
}

.slideshow-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: 35px;
	padding-right: 15px;
	padding-left: 15px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px;
	background-color: #e71321;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transform: skew(0deg, 0deg);
	-ms-transform: skew(0deg, 0deg);
	transform: skew(0deg, 0deg);
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.875rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.slideshow-button.left {
	padding-left: 48px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Red-Btn-BG-Left.svg');
	background-position: 100% 50%;
	background-size: 150px;
	text-align: center;
}

.slideshow-button.left.longer {
	width: auto;
	background-size: cover;
}

.slideshow-button.white-button {
	width: auto;
	margin-top: 1rem;
	padding-right: 22px;
	padding-left: 22px;
	background-color: #fff;
	background-image: none;
	background-size: auto;
	color: #005590;
}

.slideshow-button.secondarybutton {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0px;
	padding-left: 15px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Blue-Btn-BG.svg');
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.slideshow-button.primary {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding-top: 0px;
	padding-right: 15px;
	padding-left: 15px;
	background-color: #525252;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.slideshow-button.resouce-button {
	width: auto;
	padding-right: 30px;
	background-size: cover;
}

.slideshow-button.short {
	margin-bottom: 10px;
}

.slideshow-button.longer {
	width: auto;
	padding-right: 15px;
	background-color: #525252;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.slideshow-button.longer:hover {
	background-color: #ed1c29;
}

.text-block {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	font-weight: 700;
	text-align: center;
}

.slide-3 {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-4 {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.section-header-on-white {
	margin-top: 0rem;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.875rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	line-height: 44px;
}

.featured-product-holder {
	display: block;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.featured-product {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: auto;
	margin-bottom: 1rem;
	margin-left: 0px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: end;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.featured-product-quartered {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.featured-product-title {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	width: 100%;
	margin-top: 10px;
}

.featured-product-price {
	margin-top: 5px;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.125rem;
	line-height: 22px;
	font-weight: 600;
}

.spotlight-holder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: transparent;
}

.spotlight {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: transparent;
}

.spotlight-image-holder {
	width: 50%;
	margin-right: 20px;
	padding-right: 0px;
	background-color: transparent;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.spotlight-image-holder.education1 {
	width: 33.33%;
	height: 350px;
	margin-right: 0px;
}

.spotlight-image-holder.half-image {
	width: 100%;
	height: 50vh;
	margin-right: 0px;
}

.spotlight-image-holder.third-image {
	width: 100%;
	height: 33.3vh;
	margin-right: 0px;
}

.spotlight-image-holder.education1.left-side {
	margin-right: 10px;
	margin-left: 10px;
	background-position: 100% 50%;
	background-size: cover;
}

.spotlight-image-holder.education1.left-side.mobile-only {
	display: none;
}

.spotlight-image-holder.education2 {
	width: 33.33%;
	margin-right: 10px;
	margin-left: 10px;
}

.spotlight-image-holder.education2.left-side {
	background-position: 100% 50%;
	background-size: cover;
}

.spotlight-image-holder.education2.left-side.mobile-hidden {
	margin-right: 0px;
	margin-left: 0px;
}

.spotlight-image-holder.education2.left-side.desktop-hidden {
	display: none;
}

.div-block-4 {
	background-color: #4b7e5b;
}

.spotlight-information {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	padding-right: 60px;
	padding-left: 30px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.spotlight-information.left-side {
	padding-right: 30px;
	padding-left: 60px;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
}

.spotlight-header {
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.25rem;
	font-weight: 400;
}

.spotlight-header.left-side {
	width: 100%;
	text-align: right;
}

.spotlight-text {
	width: 100%;
	padding-bottom: 25px;
}

.spotlight-text.left-side {
	width: 100%;
	text-align: right;
}

.text-block-2 {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

.heading {
	font-size: 1.625rem;
	font-weight: 400;
}

.header-red-diamond-holder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 15px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-red-diamond {
	width: 10px;
	height: 10px;
	margin-bottom: 0.5rem;
}

.parts-store-container {
	display: block;
	padding: 4rem 0rem 5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #005590;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Triangle-Darker.svg');
	background-position: 100% 0%;
	background-size: 400px;
	background-repeat: no-repeat;
	background-attachment: scroll;
	margin-bottom: 2rem;
}

.parts-icon {
	width: 65px;
	height: 65px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Keys.svg');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.parts-header {
	height: auto;
	margin-top: -10px;
	font-family: Poppins, sans-serif;
	color: #fff;
}

.heading-2 {
	font-size: 1.875rem;
	line-height: 30px;
	font-weight: 600;
	letter-spacing: 1px;
	color: #fff;
	text-transform: uppercase;
}

.parts-information {
	font-family: Poppins, sans-serif;
	color: #fff;
	text-align: center;
}

.diamond-and-lines {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.white-diamond-line {
	width: 175px;
	margin-right: 15px;
	margin-left: 15px;
	padding-right: 0px;
	padding-left: 0px;
	border-top: 1px solid #fff;
}

.container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.companies {
	padding-top: 2rem;
	padding-bottom: 4rem;
}

.company-logo-holder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.company-logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	width: 125px;
	height: 125px;
	margin-right: 30px;
	margin-left: 30px;
	opacity: 0.5;
	-webkit-transition: opacity 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: opacity 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	cursor: pointer;
}

.company-logo img {
	width: 100%;
}

.company-logo:hover {
	opacity: 1;
}

.company-logo.hirsh-h {
	width: 200px;
	padding: 0px;
	opacity: 0.5;
}

.company-logo.hirsh-h:hover {
	opacity: 1;
}

.company-logo.hirsh-brand {
	opacity: 1;
}

.company-logo.hirsh-brand:hover {
	opacity: 1;
}

.company-logo.mexico {
	width: 141px;
}

.footer-signoff-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.8rem;
	letter-spacing: 1px;
	border-bottom: 1px solid transparent;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #bebebe;
}

.footer-links-div {
	padding: 2rem;
	background-color: #333;
	font-family: Poppins, sans-serif;
}

.link-list-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 925px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.social-media-title {
	margin-bottom: 1rem;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.quicklink-icon-image {
	width: 40px;
	height: 40px;
	margin-bottom: 0px;
}

.quicklink-icon-image._1 {
	width: 45px;
	height: 45px;
}

.quicklink-icon-image._1.repeat {
	width: 65px;
	height: 65px;
	padding: 0.5rem;
	border-radius: 1rem;
	background-color: #fff;
}

.quicklink-icon-image.repeat {
	width: 65px;
	height: 65px;
	padding: 0.5rem;
	border-radius: 1rem;
	background-color: #fff;
}

.newsletter-text-field {
	height: 100%;
	margin-bottom: 0px;
	color: #333;
}

.newsletter-text-field::-webkit-input-placeholder {
	color: #bebebe;
}

.newsletter-text-field:-ms-input-placeholder {
	color: #bebebe;
}

.newsletter-text-field::-ms-input-placeholder {
	color: #bebebe;
}

.newsletter-text-field::placeholder {
	color: #bebebe;
}

.footer-social-icon-image {
	display: block;
	width: 18px;
}

.footer-social-icon-image.linkedin {
	width: 28px;
	margin-top: -3px;
}


.footer-social-icon-image.issuu {
	width: 18px;
	height: 18px;
}

.footer-section {
	display: block;
	background-color: #333;
}

.quicklinks-repeat-div {
	display: none;
	padding-top: 0rem;
	padding-bottom: 2rem;
}

.quicklink-icon-div-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.quicklink-icon-div-wrapper.repeat {
	width: 33%;
	min-height: 275px;
	margin-bottom: 0.5%;
	padding: 1.25rem 2rem 2rem;
	border: 1px solid #dadada;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.quicklink-icon-div-wrapper.repeat:hover {
	background-color: #f1f1f1;
}

.signoff-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.5rem;
	padding-bottom: 2.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #000;
	font-family: arial;
}

.signoff-div p {
	font-family: arial;
}

.newsletter-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 215px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f1f1f1;
}

.link-list-title {
	margin-bottom: 0.5rem;
	color: #fff;
	font-size: 1.125rem;
	font-weight: 500;
	margin-top: 0px;
	padding: 0px;
	text-align: center;
	line-height: 20px;
}

.newsletter-title {
	margin-bottom: 0.75rem;
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.newsletter-form-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.newsletter-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 500px;
	max-width: 500px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.sign-off-div-wrap {
	width: 85%;
	min-width: 991px;
	padding-right: 2rem;
	padding-left: 2rem;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #8b8b8b;
	font-size: 0.9rem;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.quicklink-text-subtitle {
	color: #8b8b8b;
	font-size: 0.75rem;
	line-height: 1.1rem;
}

.quicklink-text-subtitle.para {
	text-align: left;
}

.social-media-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 215px;
	padding-top: 2rem;
	padding-bottom: 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px none #e4c700;
	border-bottom: 4px none #e4c700;
	background-color: #f5f5f5;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.quicklink-icon-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.newsletter-sub-title {
	margin-bottom: 1.5rem;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.grip-logo-image {
	width: 9rem;
	min-width: 150px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper.footer-socmed-wrapper {
	float: right;
}

.social-media-wrapper.footer-socmed-wrapper.social-2 {
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.list-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: -0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link-block-2 {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/facebook_wht_1facebook_wht.png');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.social-media-link-block-2.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	padding: 2px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px none transparent;
	border-radius: 50%;
	background-color: #ed1c29;
	background-image: none;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	cursor: pointer;
}

.social-media-link-block-2.footer-socmed-link-block:hover {
	padding: 2px;
	border: 2px none #fff;
	background-color: #ed1c29;
	-webkit-transform: translate(0px, -5px);
	-ms-transform: translate(0px, -5px);
	transform: translate(0px, -5px);
}

.social-media-link-block-2.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	padding: 2px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px none transparent;
	border-radius: 50%;
	background-color: #ed1c29;
	background-image: none;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	cursor: pointer;
}

.social-media-link-block-2.footer-socmed-link-block:hover {
	padding: 2px;
	border: 2px none #fff;
	background-color: #ed1c29;
	-webkit-transform: translate(0px, -5px);
	-ms-transform: translate(0px, -5px);
	transform: translate(0px, -5px);
}

.list-link-div {
	width: 33.33%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.newsletter-submit-button {
	min-width: 100px;
	background-color: #ffcb05;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #000;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.newsletter-submit-button:hover {
	background-color: hsla(0, 0%, 74.5%, 0.75);
}

.web-development-by {
	position: absolute;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: auto;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0.045rem;
}

.column-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.quicklink-text-title {
	font-size: 0.95rem;
	line-height: 1.3rem;
	font-weight: 700;
	text-transform: uppercase;
}

.quicklink-text-title.repeat {
	margin-bottom: 0.75rem;
}

.footer-list-link {
	margin-bottom: 0.35rem;
	padding-right: 0.25rem;
	padding-bottom: 0rem;
	padding-left: 0.35rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none hsla(0, 0%, 54.5%, 0.25);
	border-left: 3px solid transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #dbdbdb;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: none;
}

.footer-list-link:hover {
	border-bottom-color: transparent;
	border-left-color: transparent;
	color: #fff;
}

.quicklink-icon-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.quicklink-icon-text-wrap.para {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.grip-logo-link {
	float: right;
	opacity: 0.5;
	-webkit-transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
}

.grip-logo-link:hover {
	opacity: 1;
}

.footer-signoff-links {
	display: inline-block;
	margin-right: 1rem;
	border-bottom: 2px solid rgba(78, 77, 93, 0.5);
	line-height: 1.25rem;
}

.footer-signoff-links:hover {
	border-bottom-color: transparent;
}

.text-block-3 {
	color: #dbdbdb;
	text-align: center;
}

.account-icon-button {
	width: 20px;
	height: 36px;
	padding: 1rem 1.3rem;
	float: right;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.55);
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Blue.svg');
	background-position: 50% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.account-icon-button:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Red.svg');
	background-size: 18px 18px;
	opacity: 1;
}

.account-icon-button.account-icon {
	padding-right: 1.5rem;
	padding-bottom: 1rem;
	padding-left: 1.5rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Account-Blue.svg');
	background-position: 50% 50%;
}

.account-icon-button.account-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Account-Blue.svg');
}

.account-icon-button.cart-icon {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Cart-Blue.svg');
	background-position: 50% 50%;
}

.account-icon-button.cart-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Cart-Blue.svg');
}

.account-icon-button.search-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Blue.svg');
}

.shopping-cart-button {
	width: 20px;
	height: 36px;
	padding: 1rem 1.3rem;
	float: right;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.55);
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Blue.svg');
	background-position: 50% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.shopping-cart-button:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Red.svg');
	background-size: 18px 18px;
	opacity: 1;
}

.shopping-cart-button.account-icon {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Account-Blue.svg');
	background-position: 50% 50%;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
}

.shopping-cart-button.account-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Account-Blue.svg');
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.shopping-cart-button.cart-icon {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Cart-Blue.svg');
	background-position: 50% 50%;
}

.shopping-cart-button.cart-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Cart-Blue.svg');
}

.shopping-cart-button.search-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Blue.svg');
}

.phone-number {
	display: none;
	color: #dbdbdb;
	text-decoration: none;
}

.text-block-4 {
	font-family: Poppins, sans-serif;
	font-size: 0.75rem;
	font-weight: 600;
	text-decoration: none;
}

.shopping-cart-number {
	position: static;
	left: 84.4px;
	top: 13px;
	width: 15px;
	height: 15px;
	margin-top: -16px;
	margin-left: 10px;
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Cart-Number_1Cart-Number.png');
	background-position: 50% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
}

.featured-product-header {
	color: #005590;
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
	text-align: center;
}

.featured-product-info {
	padding-right: 20px;
	padding-left: 20px;
	font-size: 0.875rem;
	line-height: 18px;
	text-align: center;
}

.image-2 {
	opacity: 1;
}

.body {
	overflow: visible;
}

.heading-6 {
	font-family: Poppins, sans-serif;
	color: #005590;
	font-weight: 600;
}

.left-nav-list {
	padding-left: 0px;
}

.unordered-list {
	margin-bottom: 1rem;
}

.h5-link {
	display: inline;
	margin-bottom: 1rem;
	padding-left: 1px;
	border-bottom: 1px none #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #30679d;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

.h5-link:hover {
	border-bottom-color: transparent;
	color: #ed1c29;
}

.link-wrap {
	margin-bottom: 1rem;
	font-family: Poppins, sans-serif;
	color: #005590;
}

.h2-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #005590;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.h2-link:hover {
	border-bottom-color: transparent;
	color: #ed1c29;
}

.text-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #61bb46;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #30679d;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #ed1c29;
}

.screen-message-text {
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.screen-message-text.error {
	color: #ed1c29;
}

.button-2 {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.75rem 1.5rem;
	border: 2px none #012b5d;
	background-color: #525252;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.button-2:hover {
	background-color: #ed1c29;
	color: #fff;
}

.button-2.w--current {
	background-color: #2e80b6;
}

.button-2.secondary {
	background-color: #005590;
	color: #fff;
	text-transform: uppercase;
}

.button-2.secondary:hover {
	border-style: none;
	background-color: #ed1c29;
	color: #fff;
}

.left-nav-link-level-1 {
	display: block;
	width: 100%;
	padding: 0.5rem;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #333;
	font-weight: 600;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-level-1:hover {
	background-color: transparent;
	color: #005590;
}

.ordered-list {
	margin-bottom: 1rem;
}

.block-quote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #ed1c29;
	font-family: Poppins, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.left-nav-link-level-2 {
	display: block;
	padding: 0.5rem;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.875rem;
}

.left-nav-link-level-2:hover {
	background-color: rgba(20, 76, 141, 0.1);
}

.text-field {
	margin-bottom: 0.5rem;
	font-family: Poppins, sans-serif;
}

.inside-header-image {
	position: relative;
	height: 19rem;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.main-content-wrap.with-left-sidebar {
	width: 74%;
	padding-top: 2rem;
	padding-right: 2rem;
	padding-bottom: 2rem;
	float: right;
}

.main-content-wrap.fullwidth {
	width: 100%;
	padding: 2rem;
}

.main-content-wrap.leftright {
	width: 58%;
	padding: 2rem;
	float: right;
}

.main-content-wrap.rightsidebar {
	width: 79%;
	padding: 2rem;
	float: right;
}

.left-nav-link-level-3 {
	display: block;
	padding: 0.5rem;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.8rem;
}

.left-nav-link-level-3:hover {
	background-color: rgba(20, 76, 141, 0.1);
}

.left-nav-link-level-3.w--current {
	background-color: rgba(20, 76, 141, 0.1);
}

.styles {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.nested-unordered-list {
	margin-bottom: 0px;
}

.form-label {
	margin-bottom: 0.5rem;
	font-family: Poppins, sans-serif;
	color: #30679d;
	font-weight: 600;
}

.h3-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #30679d;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 600;
}

.h3-link:hover {
	border-bottom-color: transparent;
	color: #ed1c29;
}

.list-item {
	margin-bottom: 0.35rem;
	font-family: Poppins, sans-serif;
}

.intro-paragraph {
	font-family: Poppins, sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.h4-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #30679d;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

.h4-link:hover {
	border-bottom-color: transparent;
	color: #ed1c29;
}

.left-nav-list-item {
	margin-right: 0rem;
	margin-left: 0rem;
	margin-bottom: 0px;
}

.left-nav-list-item.no-right-margin {
	margin-right: 0rem;
	margin-left: 0rem;
}

.left-nav-list-item.half-margin-width {
	margin-right: 0rem;
	margin-left: 0rem;
	background-color: #e71321;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.error {
	background-color: rgba(51, 51, 51, 0.1);
	color: #fff;
}

.screen-message.success {
	background-color: rgba(51, 51, 51, 0.1);
	color: #fff;
}

.left-navigation {
	display: inline-block;
	width: 21%;
	margin-top: 2rem;
	margin-left: 1.5rem;
	padding-bottom: 1rem;
	float: left;
	border-top: 5px none #ed1c29;
	background-color: transparent;
}

.breadcrumbs {
	margin-bottom: 1.5rem;
}

.breadcrumb-link {
	display: inline-block;
	margin-right: 0.45rem;
	border-bottom: 1px dotted #7a7a7a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #747474;
	font-size: 0.65rem;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #333;
}

.breadcrumb-link.w--current {
	border-bottom-color: transparent;
	color: #333;
}

.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0px;
}

.left-nav-section-title-link {
	display: block;
	padding: 0.5rem 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-section-title-link:hover {
	background-color: transparent;
}

.container-2 {
	position: relative;
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container-2.inside-page-container {
	display: block;
	max-width: 1200px;
	margin-top: -3.5rem;
	background-color: #fff;
	opacity: 1;
}

.container-2.inside-page-container.leftrightside {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.container-2.inside-page-social-container {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.5)), to(hsla(0, 0%, 100%, 0.5))), url('/20170713122722/local/public/shared/assets/images/websites/Triangle-Darker.svg');
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/Triangle-Darker.svg');
	background-position: 0px 0px, 100% 50%;
	background-size: auto, 4000px;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, scroll;
	opacity: 1;
}

.h6-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #30679d;
	line-height: 1.5rem;
	font-weight: 600;
}

.h6-link:hover {
	border-bottom-color: transparent;
	color: #ed1c29;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.45rem;
	font-family: Poppins, sans-serif;
	color: #747474;
	font-size: 0.65rem;
}

.h1-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #30679d;
	font-size: 2.25rem;
	line-height: 2.75rem;
	font-weight: 600;
}

.h1-link:hover {
	border-bottom-style: dotted;
	border-bottom-color: transparent;
	color: #ed1c29;
}

.breadcrumbs-list-item {
	display: inline-block;
	margin-bottom: 0px;
}

.inside-body-section {
	background-color: rgba(51, 51, 51, 0.08);
}

.table-styling {
	margin-bottom: 0rem;
	padding-bottom: 1rem;
}

.paragraph {
	font-family: Poppins, sans-serif;
}

.paragraph-2 {
	font-family: Poppins, sans-serif;
}

.paragraph-3 {
	font-family: Poppins, sans-serif;
}

.paragraph-4 {
	font-family: Poppins, sans-serif;
}

.paragraph-5 {
	font-family: Poppins, sans-serif;
}

.paragraph-6 {
	font-family: Poppins, sans-serif;
}

.leftnavlinkblock {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-nav-red-diamond {
	width: 10px;
	height: 10px;
	margin-bottom: 0.85rem;
	opacity: 0;
}

.left-nav-link-level-1a {
	display: block;
	width: 100%;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #333;
	font-weight: 600;
	text-decoration: none;
}

.left-nav-link-level-1a:hover {
	background-color: transparent;
	color: #005590;
}

.left-nav-link-level-1b {
	display: block;
	width: 100%;
	padding: 0.5rem 0.5rem 0.5rem 2rem;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.75rem;
	text-decoration: none;
}

.left-nav-link-level-1b:hover {
	background-color: transparent;
	color: #005590;
}

.block-quote-2 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #ed1c29;
	font-family: Poppins, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.button-3 {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.75rem 1.5rem;
	border: 2px none #012b5d;
	background-color: #005590;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	text-align: center;
	text-decoration: none;
}

.button-3:hover {
	background-color: #ed1c29;
	color: #fff;
}

.button-3.w--current {
	background-color: #2e80b6;
}

.button-3.secondary {
	background-color: #5d5d5d;
	color: #fff;
}

.button-3.secondary:hover {
	border-style: none;
	background-color: #005590;
	color: #fff;
}

.social-media-title-2 {
	margin-bottom: 1rem;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.social-media-inside {
	padding-bottom: 0rem;
	background-color: rgba(51, 51, 51, 0.08);
}

.div-block-5 {
	position: static;
	width: 100%;
	height: 200px;
}

.social-media-container {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 2rem;
	background-color: transparent;
}

.companies-inside-pages {
	background-color: #333;
}

.company-logo-holder-inside {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0rem;
	padding-bottom: 1rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: #333;
}

.company-logo-inside {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100px;
	height: 50px;
	margin-right: 0px;
	margin-left: 0px;
	opacity: 0.5;
	-webkit-transition: opacity 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: opacity 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	cursor: pointer;
}

.company-logo-inside:hover {
	opacity: 1;
}

.company-logo-inside.hirsh-h {
	width: 100px;
	height: 50px;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0px;
	opacity: 0.5;
}

.company-logo-inside.hirsh-h:hover {
	opacity: 1;
}

.company-logo-inside.hirsh-brand {
	opacity: 1;
}

.company-logo-inside.mexico {
	width: 95px;
}

.company-logo-inside.hirsh-brand-inside {
	width: 100px;
	height: 50px;
	margin-right: 0px;
	margin-left: 0px;
	opacity: 1;
}

.company-logo-inside.mexico-inside {
	width: 95px;
}

.section-4 {
	background-color: #333;
}

.product-page-wrap {
	padding: 2rem;
}

.product-page-wrap.with-left-sidebar {
	width: 74%;
	padding-top: 2rem;
	padding-right: 1.5rem;
	padding-bottom: 2rem;
	float: right;
}

.slide-5 {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/desk-trans-1.png');
	background-position: 50% 50%;
	background-size: 90%;
	background-repeat: no-repeat;
}

.slider {
	height: 600px;
	background-color: transparent;
}

.product-title {
	margin-top: 10px;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-weight: 600;
	text-align: left;
}

.product-introduction {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 2rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.product-blurb {
	width: 60%;
	padding-right: 2rem;
}

.featured-image {
	width: 40%;
}

.paragraph-7 {
	font-family: Poppins, sans-serif;
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
}

.related-blog-post {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.video-block {
	width: 50%;
}

.blog-post-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.section-header-on-white-2 {
	margin-top: 0rem;
	margin-bottom: 1rem;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.875rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.red-diamond-inside-page {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 2rem;
	margin-bottom: 15px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.blog-copy {
	margin-bottom: 0px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-family: Poppins, sans-serif;
}

.text-link-2 {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #61bb46;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #30679d;
}

.text-link-2:hover {
	border-bottom-color: transparent;
	color: #ed1c29;
}

.features-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.feature-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 23%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.featured-product-header-2 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
	text-align: center;
}

.feature-blurb {
	padding-right: 20px;
	padding-left: 0px;
	font-family: Poppins, sans-serif;
	font-size: 0.875rem;
	line-height: 20px;
	text-align: left;
}

.dimensions-specs-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.spec-image-block {
	width: 50%;
}

.spec-block-holder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.text-block-5 {
	font-family: Poppins, sans-serif;
}

.spec-column-1 {
	width: 100%;
	margin-bottom: 1rem;
}

.spec-column-2 {
	width: 100%;
}

.assembly {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.assembly-blurb-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.cert-logo-title {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
	text-align: center;
}

.standards-certifications {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.standards-certifications.smallericons {
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.cert-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 23%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cert-block.smallericon {
	width: 15%;
}

.resources-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
}

.related-products {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.related-product-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.slider-holder {
	width: 65%;
}

.slide-6 {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/desk-trans-2.png');
	background-position: 50% 50%;
	background-size: 90%;
	background-repeat: no-repeat;
}

.left-arrow {
	left: 10px;
	top: 0%;
	right: auto;
	bottom: 0%;
	width: 25px;
	height: 25px;
}

.icon {
	left: auto;
	margin: auto;
	color: #005590;
	font-size: 1.5rem;
}

.right-arrow {
	left: auto;
	top: 0%;
	right: 10px;
	bottom: 0%;
	width: 25px;
	height: 25px;
}

.icon-2 {
	left: auto;
	right: auto;
	width: auto;
	height: auto;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	color: #005590;
	font-size: 1.5rem;
}

.shop-detail-form-section {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
	padding-top: 0.5em;
	border-top: 1px none #e6e6e6;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: rgba(51, 51, 51, 0.1);
	color: #005590;
}

.link {
	border-bottom: 1px none #c5c5c5;
	color: #005590;
}

.link:hover {
	border-bottom-color: #c3002f;
	color: #ed1c29;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: rgba(51, 51, 51, 0.1);
	color: #ed1c29;
}

.form-wrap-2 {
	margin-bottom: 0px;
}

.shop-detail-dimensions-label {
	min-width: 75px;
	float: left;
	font-weight: 700;
}

.shop-detail-text {
	font-family: Poppins, sans-serif;
}

.shop-detail-text.product-price {
	font-family: Poppins, sans-serif;
	font-size: 1.5em;
	line-height: 1.25em;
	font-weight: 600;
}

.shop-detail-text.product-sku {
	font-family: Poppins, sans-serif;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.shop-detail-form-field {
	width: 100%;
	margin-bottom: 0.5rem;
	background-color: #fff;
}

.shop-detail-attributes-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 35%;
	margin-bottom: 0em;
	padding: 1em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 1px none #e6e6e6;
}

.form-field-label {
	font-family: Poppins, sans-serif;
}

.shop-detail-form-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.button-4 {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.75em 1.5em 0.5em;
	background-color: #c3002f;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	line-height: 1.375em;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
}

.button-4:hover {
	background-color: #373a3d;
	color: #fff;
}

.button-4.add-to-cart {
	display: block;
	width: auto;
	height: 35px;
	max-width: none;
	margin-right: 0em;
	padding: 0.5rem 0rem 0.5rem 0.5rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #525252;
	font-family: Poppins, sans-serif;
}

.button-4.add-to-cart:hover {
	background-color: #ed1c29;
}

.button-4.add-to-cart.wishlist-button {
	background-color: #005590;
}

.button-4.add-to-cart.wishlist-button:hover {
	background-color: #ed1c29;
}

.button-4.secondary {
	background-color: #5b636b;
}

.button-4.secondary:hover {
	background-color: #373a3d;
}

.button-4.secondary.wishlist {
	padding-top: 0.5em;
	background-color: #005590;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Blue-Btn-BG.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.slider-and-cart-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-style: solid;
	border-width: 1px;
	border-color: #ddd;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.65)), to(hsla(0, 0%, 100%, 0.65)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65));
	background-position: 0px 0px;
}

.red-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.red-button-end {
	width: 35px;
	height: 35px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Red-Button-End-01.svg');
	background-position: 0% 50%;
	background-size: auto 35px;
	background-repeat: no-repeat;
}

.blue-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.blue-button-end {
	width: 35px;
	height: 35px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Blue-Button-End-01.svg');
	background-position: 0% 50%;
	background-size: auto 35px;
	background-repeat: no-repeat;
}

.text-block-6 {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	line-height: 22px;
}

.div-block-6 {
	margin-bottom: 2rem;
}

.slider-thumbnail {
	width: 23%;
	height: 50px;
	border-style: solid;
	border-width: 1px;
	border-color: #ddd;
	background-position: 50% 50%;
	background-size: 40%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.slider-thumb-holder {
	position: absolute;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 998;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: -50px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.slide-nav {
	display: none;
}

.link-block {
	width: 100%;
	height: 100%;
}

.primary-button {
	padding: 9px 15px;
	background-color: #525252;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}

.primary-button:hover {
	color: #fff;
	background-color: #ed1c29;
}

.secondary-button {
	background-color: #005590;
	font-family: Poppins, sans-serif;
	font-weight: 600;
	text-transform: uppercase;
}

.secondary-button:hover {
	background-color: #ed1c29;
}

.right-nav-col {
	width: 21%;
	margin-top: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: transparent;
}

.right-nav-div {
	margin-top: 92px;
	padding-right: 10px;
	padding-bottom: 10px;
	border-right: 6px none #edb761;
}

.sidebar-section-title {
	margin-top: 1rem;
	margin-bottom: 0rem;
	padding-left: 0px;
	background-color: transparent;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 600;
	text-transform: none;
}

.right-nav-a {
	position: relative;
	display: block;
	margin-right: 20px;
	margin-left: 20px;
	padding: 12px 2px;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.9em;
	text-decoration: none;
}

.right-nav-a:hover {
	color: #ed1c29;
}

.right-nav-a.w--current {
	border-bottom-color: #edb761;
}

.paragraph-8 {
	margin-top: 10px;
	margin-left: 20px;
	font-size: 0.9em;
}

html.w-mod-js *[data-ix="scroll-into-view-vertical"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="scroll-into-view-from-left"] {
	opacity: 0;
	-webkit-transform: translate(-50px, 0px);
	-ms-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px);
}

html.w-mod-js *[data-ix="scroll-into-view-from-right"] {
	opacity: 0;
	-webkit-transform: translate(50px, 0px);
	-ms-transform: translate(50px, 0px);
	transform: translate(50px, 0px);
}

@media (max-width: 991px) {
	.menu-button {
		display: block;
		width: 50px;
		height: 50px;
		margin-right: 0px;
		padding: 0.5rem 0.1rem;
		float: right;
		clear: left;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
		color: #000;
		text-align: center;
	}

	.menu-button:hover {
		color: #d8263c;
	}

	.menu-button.w--open {
		background-color: transparent;
		color: #000;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 25%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		border-left: 1px solid rgba(0, 0, 0, 0.5);
		-webkit-transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

	.header-top {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.nav-menu {
		z-index: 1;
		display: block;
		width: 80%;
		margin-top: 102px;
		border-style: none none solid solid;
		border-width: 1px;
		border-color: rgba(0, 0, 0, 0.5);
		background-color: #fff;
	}

	.search-container-top {
		display: none;
		overflow: hidden;
		height: 0px;
		background-color: #fff;
	}

	.header-search-form.search-top-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 80%;
		height: auto;
		margin-right: 2rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.close-link {
		width: 32px;
		height: 32px;
		margin-bottom: 0rem;
		border-radius: 14px;
		background-color: #fff;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/close_x_black.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		color: #fff;
	}

	.close-link:hover {
		background-color: #dedfe4;
		opacity: 1;
	}

	.header-flex-container {
		display: block;
		margin-bottom: 1.25rem;
		padding: 0rem 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.utility-nav {
		height: auto;
		padding-top: 5px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-search-field {
		width: 75%;
		border-style: solid none solid solid;
		border-width: 1px;
		border-color: rgba(121, 118, 118, 0.5);
		border-radius: 3px 0px 0px 3px;
	}

	.search-close-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(121, 118, 118, 0.5);
	}

	.two-part-mobile-nav-wrapper.small-mobile {
		display: none;
	}

	.menu-icon {
		color: #005590;
		font-size: 2rem;
		line-height: 2rem;
	}

	.menu-text {
		color: #005590;
		font-size: 0.75rem;
		line-height: 0.75rem;
		text-transform: uppercase;
	}

	.nav-menu-wrap {
		position: relative;
		z-index: 1;
		font-family: Poppins, sans-serif;
	}

	.arrow-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		font-size: 1.5rem;
	}

	.arrow-icon:hover {
		color: #d8263c;
	}

	.header-section {
		position: fixed;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		padding-right: 0%;
		padding-left: 0%;
		height: 104px;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		float: right;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.search-dd-button {
		display: none;
	}

	.search-dd-button.mobile-search-dd-button {
		display: block;
		width: 50px;
		height: 50px;
		margin-right: 10px;
		padding: 0.5rem;
		float: right;
		clear: right;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.search-dd-button.mobile-search-dd-button:hover {
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_red-D8263C.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.desktop-search-button.search-icon:hover {
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Blue.svg');
		background-position: 50% 50%;
		background-size: 18px;
		background-repeat: no-repeat;
	}

	.header-search-form-wrapper.search-top-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid #5f5f5f;
		background-color: transparent;
	}

	.button.search-button.search-top-button {
		width: 25%;
		border-radius: 0px 3px 3px 0px;
	}

	.main-nav-link {
		width: 100%;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom: 1px solid rgba(121, 118, 118, 0.5);
		text-align: left;
	}

	.main-nav-link:hover {
		box-shadow: none;
	}

	.main-nav-link.w--current {
		box-shadow: none;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 100%;
		margin-right: 0px;
		padding-left: 1rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: solid;
		border-bottom-color: rgba(0, 0, 0, 0.5);
		-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

	.main-nav-link.mobile-duplicate:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.mobile-duplicate.w--current {
		height: auto;
		box-shadow: inset 2px 0 0 0 #ec1c24;
		color: #d8263c;
	}

	.main-nav-link.mobile-duplicate.w--current:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.two-part {
		padding-left: 1rem;
		border-bottom-style: none;
		border-bottom-color: rgba(0, 0, 0, 0.5);
	}

	.search-exit-link {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.large-search-hidden-div {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
		display: none;
		max-height: none;
		min-height: 0px;
		padding-top: 225px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
		opacity: 0;
	}

	.search-bar-div {
		padding-bottom: 2rem;
	}

	.exit-button {
		width: 78px;
		height: 78px;
		margin-top: 144px;
	}

	.red-menu-diamond {
		display: none;
	}

	.search {
		width: 90%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.hidden-search-holder {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.main-slider-arrow-icon-2 {
		font-size: 1.8rem;
	}

	.slider-header-emphasis {
		font-size: 35px;
		line-height: 34px;
	}

	.slider-paragraph {
		display: block;
		max-width: 400px;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
	}

	.slider-header {
		padding-top: 0.25rem;
		font-size: 1.5rem;
		line-height: 39px;
	}

	.slider-content-wrapper {
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slide-text-wrap {
		max-width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.slider-widget {
		height: 380px;
	}

	.slider-arrow {
		width: 50px;
		height: 50px;
		margin-right: 0rem;
		margin-bottom: 1rem;
	}

	.slider-arrow.left {
		width: 50px;
		height: 50px;
		margin-left: 0rem;
		font-size: 1.5rem;
	}

	.caption-and-button {
		padding-left: 0px;
	}

	.slideshow-button {
		font-weight: 600;
	}

	.featured-product {
		width: 50%;
		height: auto;
	}

	.featured-product-quartered {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.featured-product-title {
		text-align: center;
	}

	.red-diamond-mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 15px;
		height: 15px;
		margin-left: 10px;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.spotlight-holder {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		margin-top: 0px;
		margin-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.spotlight-holder.information-left {
		margin-top: 0px;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}

	.spotlight {
		padding-bottom: 0rem;
	}

	.spotlight-image-holder.education1 {
		width: 49%;
		height: 250px;
		margin-right: 0px;
	}

	.spotlight-image-holder.education1.left-side {
		margin-right: 0px;
		margin-left: 0px;
	}

	.spotlight-image-holder.education2 {
		display: block;
		width: 49%;
		height: 250px;
		margin-right: 0px;
		margin-left: 0px;
	}

	.spotlight-information {
		width: 100%;
		padding: 30px;
		background-size: 250px;
	}

	.spotlight-information.left-side {
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	.heading {
		margin-top: 0px;
	}

	.parts-store-container {
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/Triangle-Darker.svg');
		background-position: 100% 50%;
		background-size: 400px;
	}

	.link-list-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.quicklink-icon-div-wrapper {
		width: 50%;
		padding-bottom: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.quicklink-icon-div-wrapper.repeat {
		min-height: 338px;
		padding: 1rem;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-bottom: 1.5rem;
		float: left;
	}

	.quicklink-icon-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column-2 {
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.social-media-wrapper.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.list-link-div {
		width: 33.33%;
		margin-bottom: 1rem;
	}

	.column-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-list-link {
		text-align: center;
	}

	.grip-logo-link {
		margin-right: 0rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		float: right;
	}

	.footer-signoff-links {
		margin-right: 0.75rem;
		margin-left: 0rem;
	}

	.main-content-wrap.with-left-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.main-content-wrap.leftright {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.main-content-wrap.rightsidebar {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.container-2 {
		width: 100%;
	}

	.social-media-inside {
		background-color: rgba(0, 85, 144, 0.1);
	}

	.product-page-wrap.with-left-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.slider {
		height: 500px;
	}

	.related-blog-post {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.video-block {
		width: 100%;
	}

	.blog-post-block {
		width: 100%;
		padding-top: 1rem;
	}

	.feature-block {
		width: 47%;
		margin-bottom: 1rem;
	}

	.dimensions-specs-block {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.spec-image-block {
		width: 100%;
	}

	.spec-block-holder {
		width: 100%;
	}

	.assembly {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.assembly-blurb-block {
		width: 100%;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.standards-certifications {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.slider-holder {
		width: 100%;
	}

	.left-arrow {
		left: 0px;
	}

	.right-arrow {
		right: 0px;
	}

	.shop-detail-attributes-wrap {
		width: 100%;
		padding-top: 2em;
	}
}

@media (max-width: 767px) {
	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 40px;
		height: 40px;
		margin-top: 0px;
		margin-right: 0px;
		clear: none;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.header-top {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.brand {
		display: block;
		width: 275px;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
	}

	.brand.w--current {
		margin-top: 0rem;
		margin-left: auto;
	}

	.nav-menu {
		margin-top: 81px;
	}

	.header-search-form.search-top-form {
		margin-right: 1rem;
	}

	.header-flex-container {
		margin-bottom: 0rem;
		padding: 0.75rem 0rem;
	}

	.utility-nav {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 1rem;
		padding-top: 0px;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		align-self: auto;
		border-top: 1px none rgba(121, 118, 118, 0.5);
	}

	.navbar {
		display: block;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.menu-icon {
		position: relative;
		bottom: 3px;
	}

	.menu-text {
		display: none;
	}

	.header-section {
		position: fixed;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		height: 83px;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		float: none;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.search-dd-button.mobile-search-dd-button {
		width: 40px;
		height: 40px;
	}

	.header-search-form-wrapper.search-top-wrapper {
		height: auto;
	}

	.search-exit-link {
		width: 60px;
		height: 60px;
		background-size: auto 32px;
	}

	.search-go-button {
		width: 55px;
		height: 55px;
		min-width: 55px;
		padding: 0.35rem;
	}

	.large-search-hidden-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0px;
		opacity: 0;
	}

	.search-bar-button-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.exit-button {
		width: 60px;
		height: 60px;
		margin-top: 132px;
	}

	.search-bar-field {
		margin-right: 4rem;
		margin-left: 4rem;
	}

	.search {
		width: 95%;
	}

	.slider-nav {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.slider-header-emphasis {
		font-size: 1.625rem;
	}

	.slider-paragraph {
		display: block;
		font-size: 14px;
		line-height: 20px;
	}

	.slider-header {
		padding-top: 0.25rem;
		padding-right: 10px;
		padding-left: 8px;
		font-size: 1.625rem;
		line-height: 32px;
	}

	.slider-content-wrapper {
		height: 100%;
	}

	.slide-text-wrap {
		max-width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.slider-link-block {
		height: 100%;
	}

	.slider-widget {
		height: 330px;
	}

	.slider-arrow {
		top: 40%;
		margin-right: 0rem;
	}

	.slider-arrow.left {
		top: 40%;
		margin-left: 0rem;
	}

	.caption-and-button {
		width: auto;
		padding-right: 10px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		background-color: rgba(0, 0, 0, 0.7);
	}

	.featured-product {
		width: 50%;
	}

	.spotlight-holder {
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between;
	}

	.spotlight-holder.information-left {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.spotlight-image-holder.education1 {
		width: 100%;
		height: 250px;
		margin-bottom: 20px;
	}

	.spotlight-image-holder.education1.left-side {
		display: block;
		margin-bottom: 0px;
	}

	.spotlight-image-holder.education1.left-side.mobile-only {
		display: block;
	}

	.spotlight-image-holder.education2 {
		width: 100%;
		height: 250px;
	}

	.spotlight-image-holder.education2.left-side {
		display: block;
	}

	.spotlight-image-holder.education2.left-side.mobile-hidden {
		display: block;
		margin-bottom: 20px;
	}

	.spotlight-image-holder.education2.left-side.desktop-hidden {
		display: block;
	}

	.spotlight-information {
		width: 100%;
		padding-right: 20px;
		padding-left: 20px;
		background-size: 250px;
	}

	.spotlight-information.left-side {
		padding-left: 20px;
		background-size: 250px;
	}

	.company-logo-holder {
		padding-right: 60px;
		padding-left: 60px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.company-logo {
		width: 50%;
		margin-right: 0px;
		margin-bottom: 2rem;
		margin-left: 0px;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.company-logo.hirsh-h {
		width: 50%;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.company-logo.mexico {
		width: 50%;
		padding-right: 50px;
		padding-left: 50px;
	}

	.footer-links-div {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.link-list-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}

	.quicklink-icon-div-wrapper {
		width: 50%;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.quicklink-icon-div-wrapper.repeat {
		width: 50%;
	}

	.newsletter-form {
		width: 350px;
	}

	.footer-copyright {
		float: none;
	}

	.footer-signoff-link-wrap {
		display: inline-block;
		float: none;
	}

	.column-2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.list-link-div {
		width: 50%;
	}

	.web-development-by {
		right: 34%;
	}

	.column-3 {
		overflow: hidden;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-list-link {
		text-align: center;
	}

	.grip-logo-link {
		margin-bottom: 0rem;
		float: none;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.text-block-3 {
		margin-bottom: 20px;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.button-2 {
		padding: 0.5rem 0.75rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.inside-header-image {
		height: 15rem;
	}

	.main-content-wrap.with-left-sidebar {
		padding-top: 1rem;
	}

	.main-content-wrap.leftright {
		padding-top: 1rem;
	}

	.main-content-wrap.rightsidebar {
		padding-top: 1rem;
	}

	.button-3 {
		padding: 0.5rem 0.75rem;
	}

	.company-logo-holder-inside {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}

	.company-logo-inside {
		width: 20%;
		margin-right: 0px;
		margin-bottom: 2rem;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.company-logo-inside.hirsh-h {
		width: 20%;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.company-logo-inside.hirsh-brand-inside {
		width: 20%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.company-logo-inside.mexico-inside {
		width: 20%;
	}

	.product-page-wrap.with-left-sidebar {
		padding-top: 1rem;
	}

	.slider {
		height: 325px;
	}

	.product-introduction {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.product-blurb {
		width: 100%;
		padding-right: 0rem;
		padding-bottom: 1rem;
	}

	.featured-image {
		width: 100%;
	}

	.cert-block {
		width: 47%;
	}

	.related-products {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.related-product-block {
		width: 100%;
		margin-bottom: 1rem;
	}

	.slider-holder {
		width: 100%;
	}

	.shop-detail-attributes-wrap {
		width: 100%;
		margin-top: 0em;
	}

	.button-4.secondary.wishlist {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
}

@media (max-width: 479px) {
	.menu-button {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		line-height: 1.6rem;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.header-top {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.brand {
		width: 212px;
	}

	.brand.w--current {
		width: 212px;
	}

	.nav-menu {
		width: 100%;
		margin-top: 73px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.search-container-top {
		overflow: hidden;
	}

	.header-search-form.search-top-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		margin-right: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-flex-container {
		padding-top: 0.75rem;
	}

	.logo {
		display: inline-block;
		max-width: 100%;
		float: none;
	}

	.utility-nav {
		margin-top: 0.75rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.header-search-field {
		width: 70%;
		float: none;
	}

	.search-close-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 60px;
		margin-left: 1rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.two-part-mobile-nav-wrapper.small-mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		background-color: #ececec;
	}

	.menu-text {
		display: none;
	}

	.header-section {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		height: 70px;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 40px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.search-dd-button.mobile-search-dd-button {
		margin-right: 5px;
	}

	.desktop-search-button {
		display: block;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.desktop-search-button.account-icon {
		display: none;
	}

	.desktop-search-button.cart-icon {
		display: none;
	}

	.header-search-form-wrapper {
		min-width: 100%;
	}

	.header-search-form-wrapper.search-top-wrapper {
		height: auto;
		padding: 0.5rem 0.5rem 0.5rem 1rem;
	}

	.button.search-button {
		width: 30%;
		min-width: 0px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 0.9rem;
	}

	.button.search-button.search-top-button {
		width: 30%;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
		float: none;
	}

	.main-nav-link {
		padding-left: 0.5rem;
	}

	.main-nav-link.mobile-duplicate.w--current {
		width: 100%;
	}

	.search-field-label {
		margin-bottom: 1rem;
	}

	.search-exit-link {
		width: 55px;
		height: 55px;
		background-size: 30px 30px;
	}

	.search-go-button {
		width: 55px;
		height: 55px;
		min-width: 55px;
		margin-left: 0.5rem;
		padding: 0.35rem;
		border-radius: 0rem;
		background-color: transparent;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/Next-Arrow-Blue.svg');
		background-size: 30px 30px;
		background-repeat: no-repeat;
		opacity: 1;
	}

	.search-go-button:hover {
		border-radius: 0rem;
		background-size: 30px 30px;
		opacity: 0.85;
	}

	.large-search-hidden-div {
		display: none;
		height: 100%;
		min-height: 450px;
		padding-top: 191px;
		opacity: 0;
	}

	.search-bar-button-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.search-bar-div {
		width: 100%;
	}

	.exit-button {
		margin-top: 136px;
	}

	.search-bar-field {
		margin-bottom: 1rem;
		margin-left: 0rem;
	}

	.search {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slider-nav {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.slider-header-emphasis {
		font-size: 1.75rem;
	}

	.slider-paragraph {
		display: block;
		margin-top: 0rem;
		margin-right: 0rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		border-right-style: none;
		text-align: center;
	}

	.slider-header {
		width: 100%;
		margin-bottom: 0px;
		padding-top: 0.5rem;
		padding-bottom: 0rem;
		font-size: 1.375rem;
		line-height: 26px;
		text-align: center;
	}

	.slider-content-wrapper {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slide-text-wrap {
		position: static;
		max-width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slider-widget {
		height: 425px;
	}

	.slider-arrow {
		top: 86%;
		margin-right: 0rem;
		font-size: 1.5rem;
	}

	.slider-arrow.left {
		left: 0px;
		top: 86%;
		margin-left: 0px;
	}

	.caption-and-button {
		width: 100%;
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slideshow-button {
		margin-bottom: 10px;
	}

	.slideshow-button.left.longer {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.section-header-on-white {
		line-height: 36px;
	}

	.featured-product {
		width: 100%;
	}

	.red-diamond-mobile.small-mobile {
		width: 20px;
		height: 20px;
	}

	.spotlight-holder {
		height: auto;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}

	.spotlight-holder.information-left {
		height: auto;
		margin-top: 0rem;
	}

	.spotlight-image-holder.education1 {
		width: 100%;
		height: 200px;
		margin-right: 0px;
	}

	.spotlight-image-holder.education1.left-side {
		display: block;
	}

	.spotlight-image-holder.education1.left-side.mobile-only {
		display: block;
	}

	.spotlight-image-holder.education2 {
		height: 200px;
	}

	.spotlight-information {
		width: 100%;
		height: auto;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.spotlight-information.left-side {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.text-block-2 {
		text-align: center;
	}

	.heading {
		text-align: center;
	}

	.parts-store-container {
		background-size: 450px;
	}

	.heading-2 {
		font-size: 1.625rem;
	}

	.white-diamond-line {
		width: 100px;
	}

	.company-logo-holder {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.company-logo {
		width: 49%;
		margin-bottom: 0rem;
		padding-right: 10px;
		padding-left: 10px;
	}

	.company-logo.hirsh-h {
		width: 49%;
		padding-right: 5px;
		padding-left: 15px;
	}

	.company-logo.hirsh-brand {
		width: 49%;
	}

	.company-logo.mexico {
		width: 49%;
		padding-right: 30px;
		padding-left: 30px;
	}

	.social-media-title {
		line-height: 2.5rem;
	}

	.newsletter-text-field {
		height: 42px;
		margin-bottom: 0.75rem;
	}

	.quicklinks-repeat-div {
		padding-bottom: 0rem;
	}

	.quicklink-icon-div-wrapper {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.quicklink-icon-div-wrapper.repeat {
		width: 100%;
		min-height: auto;
		margin-bottom: 0.25rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.newsletter-div {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.newsletter-title {
		line-height: 2.5rem;
	}

	.newsletter-form {
		width: 275px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-copyright {
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.newsletter-sub-title {
		font-size: 0.8rem;
	}

	.column-2 {
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.list-link-wrapper {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.social-media-link-block-2.footer-socmed-link-block {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.social-media-link-block-2.footer-socmed-link-block {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.list-link-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 1.5rem;
		padding-bottom: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid hsla(0, 0%, 54.5%, 0.25);
	}

	.list-link-div.last {
		margin-bottom: 0rem;
	}

	.newsletter-submit-button {
		height: 40px;
	}

	.web-development-by {
		right: 22%;
	}

	.column-3 {
		margin-bottom: 0.5rem;
	}

	.quicklink-text-title {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.footer-list-link:hover {
		border-left-color: transparent;
	}

	.quicklink-icon-text-wrap {
		text-align: center;
	}

	.grip-logo-link {
		float: none;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.text-block-3 {
		text-align: center;
	}

	.account-icon-button {
		display: block;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.shopping-cart-button {
		display: block;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.button-2 {
		margin-bottom: 0.5rem;
	}

	.inside-header-image {
		background-position: 0px 0px, 18% 8%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.main-content-wrap.with-left-sidebar {
		padding-top: 1rem;
	}

	.main-content-wrap.fullwidth {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.main-content-wrap.leftright {
		padding-top: 1rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.main-content-wrap.rightsidebar {
		padding-top: 1rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.button-3 {
		margin-bottom: 0.5rem;
	}

	.social-media-title-2 {
		line-height: 2.5rem;
	}

	.company-logo-holder-inside {
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.company-logo-inside {
		width: 50%;
		margin-bottom: 1rem;
		padding-right: 10px;
		padding-left: 10px;
	}

	.company-logo-inside.hirsh-h {
		width: 50%;
		margin-bottom: 1rem;
		padding-right: 0px;
		padding-left: 0px;
	}

	.company-logo-inside.hirsh-brand-inside {
		width: 50%;
		margin-bottom: 1rem;
	}

	.company-logo-inside.mexico-inside {
		width: 50%;
	}

	.product-page-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.product-page-wrap.with-left-sidebar {
		padding-top: 1rem;
	}

	.slider {
		height: 250px;
	}

	.blog-post-block {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.section-header-on-white-2 {
		line-height: 36px;
	}

	.feature-block {
		width: 100%;
	}

	.spec-block-holder {
		padding: 1rem;
	}

	.assembly-blurb-block {
		padding: 1rem;
	}

	.shop-detail-form-buttons-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.button-4.add-to-cart {
		max-width: 100%;
		margin-right: 0em;
	}

	.button-4.secondary.wishlist {
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}
}

/****************** Webflow ********************/
/****************************************************/
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	overflow-x: hidden !important;
	font-family: Poppins, sans-serif;
}
/* CONTROL PHONE NUMBER COLOR */
a[href^=tel] {
	color: #2e2e2e;
	text-decoration: none;
}
/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */
::-moz-selection {
	background-color: #797676;
	color: #ffffff;
}
/* Safari browser */
::selection {
	background-color: #797676;
	color: #ffffff;
}
/* KEEP MAIN NAV HOVER STATE WHEN DROPDOWN IS OPEN - requires JS below */
.hovered {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #ed1c29;
}
/* STICKY ADJUSTMENTS - REQUIRES JS BELOW */
@media screen and (min-width: 992px) {
	/* desktop only */
	.sticky.header-section {
		background-color: white;
	}

	.sticky .header-flex-container {
		margin-bottom: 0;
	}

	.sticky .brand {
		width: 250px;
		z-index: 2000;
	}

	.sticky .navbar {
		margin-top: 0px;
	}

	div.utility-nav {
		padding-top: 0;
	}
}
/* ADJUSTMENT OF INSIDE PAGE BANNER FOR LAPTOPS */
@media screen and (min-width: 992px) and (max-height: 828px) {
	.inside-page-banner-section {
		height: 400px;
	}
}

.main-content-wrapper *:first-child {
	margin-top: 0px;
}

input.w-button {
	display: none;
}

a[href^=tel] {
	color: #dbdbdb !important;
}


/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Header ***********************/
/*************************************************/
.main-container {
	overflow: hidden;
}

.cart-number {
	position: absolute;
	min-width: 15px;
	min-height: 15px;
	border-radius: 50%;
	background-color: #e71321;
	color: #fff;
	font-size: 0.625rem;
	line-height: 10px;
	text-decoration: none;
	text-align: center;
	padding: 3px 2px 0px 2px;
	margin-top: -17px;
	margin-left: 9px;
}

.desktop-search-button {
	background-color: transparent;
}

.search-go-button {
	border: 0px;
	cursor: pointer;
	background-color: transparent;
}

@media all and (-ms-high-contrast: none) {
	*::-ms-backdrop,
	.container-wrapper {
		left: 0;
	}

	*::-ms-backdrop,
	.hidden-search-holder {
		left: 0;
	}

	*::-ms-backdrop,
	.mm-wrapper_opened .header-section {
		display: none;
	}
}

@media (max-width: 479px) {
	.header-top {
		display: block;
	}

	.brand {
		float: left;
	}

	.utility-nav {
		width: 90px;
		float: right;
		margin-top: 4px;
		padding-bottom: 10px;
	}
}

/*************** Mobile Menu *********************/
/*************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background-color: #fff;
	border-left: 1px solid #7f7f7f;
}

.mm-listitem:after {
	left: 0;
	border-color: rgba(121, 118, 118, 0.5);
}

.mobile-navigation-menu li a.nav-link {
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.98rem;
	line-height: 1.4rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
}

.mobile-navigation-menu li a.nav-link:hover,
.mobile-navigation-menu li.active a.nav-link {
	color: #e71321;
}

.mobile-navigation-menu li a.nav-link:before {
	display: inline-block;
	height: 8px;
	margin-right: 10px;
	width: 10px;
	content: "";
	background-image: url('/20170713122722/assets/images/Diamond-Red.svg');
	background-repeat: no-repeat;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #333;
}

.mm-btn_next:after {
	right: 42px;
	width: 12px;
	height: 12px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 85px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 85px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.98rem;
	line-height: 1.4rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
}

.mm-navbar {
	border-color: #333;
	height: 42px;
	text-align: left;
	border-color: rgba(121, 118, 118, 0.5);
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #000;
}

.mm-listitem > a,
.mm-listitem > span {
	padding: 12px 10px 12px 20px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -18px;
}

.mm-listitem .mm-btn_next:before {
	border-color: rgba(121, 118, 118, 0.5);
	margin-top: 10px;
	margin-bottom: 10px;
}

.mm-panel .mm-listview .special-links.mm-listitem {
	display: none !important;
}

@media (max-width: 508px) {
	.account-icon-button.account-icon {
		display: none;
	}

	.account-icon-button.cart-icon {
		display: none;
	}

	.shopping-cart-button.account-icon {
		display: none;
	}

	.shopping-cart-button.cart-icon {
		display: none;
	}

	.mm-panel .mm-listview .special-links.mm-listitem {
		display: block !important;
	}

	/* Mobile menu icons */
	.mobile-navigation-menu .special-links.account a.nav-link:before {
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/Account-Red.svg');
		height: 18px;
		width: 18px;
		background-repeat: no-repeat;
		background-position: 0;
		background-size: 18px 18px;
	}

	.mobile-navigation-menu .special-links.cart a.nav-link:before {
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/Cart-Red.svg');
		height: 18px;
		width: 18px;
		background-repeat: no-repeat;
		background-position: 0;
		background-size: 18px 18px;
	}

	li.special-links a.nav-link {
		display: flex !important;
		align-items: center;
	}
}

/****************** Acesible menu *******************/
/*****************************************************/
.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.accesible-navigation-menu ul li {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	position: relative;
}

.accesible-navigation-menu ul li.dropdown-wrap {
	background-position: center right;
	background-repeat: no-repeat;
	background-size: 8px 8px;
	margin-right: 20px;
	padding-right: 30px;
	background-image: url('/20170713122722/assets/images/Diamond-Red.svg');
}

.accesible-navigation-menu ul li.dropdown-wrap:last-child {
	background: none;
	margin-right: 0px;
	padding-right: 0px;
}

.accesible-navigation-menu ul li.dropdown-wrap a.active {
	color: #e71321;
}

.accesible-navigation-menu .container-wrapper {
	position: absolute;
	top: 100%;
	min-height: 0px;
	transition: opacity 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	opacity: 0;
	height: 0;
	border-color: rgba(121, 118, 118, 0.5);
	background-color: #fff;
	box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.5);
	width: 200px;
}

.accesible-navigation-menu ul li.dropdown-wrap.open .container-wrapper,
.accesible-navigation-menu ul li.dropdown-wrap .container-wrapper:hover {
	opacity: 1;
	height: auto;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list {
	display: none;
	margin: 0;
	padding: 0.5rem 0.5rem 0.25rem;
	list-style-type: none;
	width: 100%;
}

.accesible-navigation-menu ul li.dropdown-wrap.open .container-wrapper ul.nav-drop-list,
.accesible-navigation-menu ul li.dropdown-wrap .container-wrapper:hover ul.nav-drop-list {
	display: inline-block;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li {
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li a {
	padding: 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	font-size: 0.98rem;
	line-height: 1.4rem;
	letter-spacing: 0rem;
	text-decoration: none;
	width: 100%;
	display: inline-block;
	font-family: Poppins, sans-serif;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li a:hover,
.accesible-navigation-menu .container-wrapper ul.nav-drop-list li a.w--current {
	color: #e71321;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none;
	}
}


/********************** Slideshow *************************/
/**********************************************************/
.slider-paragraph.no-link {
	margin-right: 0px;
	border-right: 0px;
}

/******************** Homepage **********************/
/*****************************************************/
.slideshow-button.white-button a,
.slideshow-button.white-button a:hover {
	text-decoration: none;
	color: inherit;
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
}

.slider-section-2 .slideshow-button:hover {
	color: #fff;
}

.diamond-and-lines img {
	height: 6px;
	width: 8px;
}

.blue-text {
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.125rem;
	line-height: 22px;
	font-weight: 600;
	text-decoration: none;
}

.featured-product-header a,
.featured-product-header a:hover {
	text-decoration: none;
	color: inherit;
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
}

.text-block-2 p:last-child {
	margin-bottom: 0px;
}

.parts-information {
	width: 100%;
}

/****************** Inside Page *******************/
/*************************************************/
img {
	height: auto;
	max-width: 100%;
	display: inline-block;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

.main-content-wrap {
	min-height: 500px;
}

.container-2.inside-page-container.leftrightside.only-left {
	display: block;
}

h2.left-nav-section-title-link {
	margin-top: 0px;
	margin-bottom: 0px;
}

.left-nav-section-title-link a,
.left-nav-section-title-link a:hover {
	box-shadow: none;
	border-bottom: 0px;
	text-decoration: none;
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	color: inherit;
	font-family: inherit;
}

.left-nav-list li a.active {
	color: #005590;
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

@media (max-width: 991px) {
	.container-2.inside-page-container.leftrightside {
		display: block;
	}

	.right-nav-col {
		width: 100%;
		margin-top: 0px;
		padding-top: 20px !important;
		padding-left: 30px;
		padding-right: 30px;
		border-top: 1px solid #d6d6d6;
	}

	.right-nav-col .box {
		padding-left: 0px;
		padding-right: 0px;
	}

	.right-nav-col .box .content {
		margin-right: 0px !important;
		margin-left: 0px !important;
	}
}


/******************* Right Sidebar *******************/
/*****************************************************/
.right-nav-col {
	padding-top: 92px;
}

.right-nav-col .box {
	margin-bottom: 1rem;
	padding-right: 10px;
	padding-bottom: 10px;
}

.right-nav-col .box h4,
.right-nav-col .box h3 {
	margin-top: 0px;
	margin-bottom: 0rem;
	padding-left: 0px;
	background-color: transparent;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 600;
	text-transform: none;
}

.right-nav-col .box .content {
	margin-right: 20px;
	margin-left: 20px;
}

.right-nav-col .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-nav-col .box .contentbox_item_image {
	width: 24px;
}

.right-nav-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-nav-col .box.documents a,
.right-nav-col .box.links a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.9em;
	text-decoration: none;
}

.right-nav-col .box.documents a:hover,
.right-nav-col .box.links a:hover {
	color: #ed1c29;
}

.right-nav-col .box.documents table {
	width: 100%;
}

.right-nav-col .box.documents table tr td:first-child {
	width: 24px;
	padding-right: 7px;
}

.right-nav-col .box.documents table tr td {
	border-bottom: 1px dotted #aaa;
	text-align: left;
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
}

.right-nav-col .box.links p {
	margin-top: 0px;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	display: inline-block;
	width: 100%;
	border-bottom: 1px dotted #aaa;
}

.right-nav-col .box td.contentbox_item_image img {
	max-width: none;
}

/****************** Footer ***********************/
/*************************************************/
.phone-desktop {
	display: inline;
}

.phone-mobile {
	display: none;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0 !important;
	border: 0px !important;
	line-height: 0;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.6rem 1rem !important;
	line-height: 1.1rem;
	box-shadow: 0 0 5px #000;
	z-index: 5000;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
	font-family: Poppins, sans-serif;
	color: #005590;
	font-weight: 600;
}

h1 a {
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #30679d;
	font-size: 2.25rem;
	line-height: 2.75rem;
	font-weight: 600;
}

h2 a {
	transition: all 200ms ease;
	color: #005590;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
}

h3 a {
	transition: all 200ms ease;
	color: #30679d;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 600;
}

h4 a {
	transition: all 200ms ease;
	color: #30679d;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

h5 a {
	transition: all 200ms ease;
	color: #30679d;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

h6 a {
	font-size: 0.875rem;
	transition: all 200ms ease;
	color: #30679d;
	line-height: 1.5rem;
	font-weight: 600;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #ed1c29;
}

p {
	font-family: Poppins, sans-serif;
}

/* links */
a {
	transition: all 200ms ease;
	color: #30679d;
	text-decoration: underline;
}

a:hover {
	color: #ed1c29;
}

li {
	margin-bottom: 0.35rem;
	font-family: Poppins, sans-serif;
}

/* buttons */
.button,
.primary,
.cms_form_button.primary,
button[type="submit"],
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.secondary,
.cms_form_button.secondary,
.tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	padding: 0.75rem 1.5rem;
	background-color: #525252;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-transform: uppercase;
	border: 0px;
	margin-bottom: 10px;
	margin-top: 5px;
	border-radius: 0px;
}

.cms_form_button.primary span {
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.button:hover,
.primary:hover,
.cms_form_button.primary:hover,
button[type="submit"]:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover,
.secondary:hover,
.cms_form_button.secondary:hover,
.tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	background-color: #ed1c29;
	color: #fff;
	border: 0px;
}

.secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	background-color: #005590;
	color: #fff;
	border: 0px;
}

.secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #ed1c29;
	color: #fff;
	border: 0px;
}

.tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	background-color: #005590;
	color: #fff;
	border: 0px;
}

.tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #ed1c29;
	color: #fff;
	border: 0px;
}

.cms_form_button.primary span,
.cms_form_button.primary:hover span,
.cms_form_button.primary span:hover,
.cms_form_button.secondary span,
.cms_form_button.secondary:hover span,
.cms_form_button.secondary span:hover,
.cms_form_button.tertiary span,
.cms_form_button.tertiary:hover span,
.cms_form_button.tertiary span:hover {
	color: #fff;
	line-height: 18px;
	padding: 0px;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: rgba(51, 51, 51, 0.1);
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: rgba(51, 51, 51, 0.1);
	font-family: Poppins, sans-serif;
	color: #ed1c29;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

#message.success p:last-child,
#message.error p:last-child {
	margin-bottom: 0px;
}

/*tables*/
table.styled {
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #005590;
	color: white;
	font-weight: normal;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: darkgray;
	color: black;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	box-shadow: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-container input[type="text"],
.inside-page-container input[type="tel"],
.inside-page-container input[type="password"],
.inside-page-container input[type="email"],
.inside-page-container select,
.inside-page-container textarea,
.inside-page-container input[type="search"] {
	max-width: 100%;
	padding: 6px 8px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.inside-page-container textarea {
	resize: vertical;
}

label {
	font-weight: 500;
}

@media (max-width: 991px) {
	.inside-page-container input[type="text"],
	.inside-page-container input[type="tel"],
	.inside-page-container input[type="password"],
	.inside-page-container input[type="email"],
	.inside-page-container select,
	.inside-page-container textarea,
	.inside-page-container input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left: 5px solid #e2e2e2;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #ed1c29;
	font-family: Poppins, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}


/*********************** Modules ********************/
/****************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: uppercase;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #878787;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
		font-size: 0.9rem;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	#s-customer-register fieldset #ffPhone,
	#s-customer-register fieldset #ffPhoneExtension,
	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/* datepicker */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faq */
#faqs_module .cms_title h2 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.3rem;
	border-color: hsla(0, 0%, 66%, 0.35);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
	display: none;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}


@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: #e9ece3;
}

#locations_module.list h2,
#locations_module.list h2 a {
	font-size: 1.5rem;
	line-height: 1.8rem;
}


#locations_module.list h3,
#locations_module.list h3 a {
	font-size: 1.2rem;
	line-height: 1.5rem;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* resources */
#moduleDocuments_module.cms_list .cms_category_icon_title_row .cms_title {
	font-size: 1.5rem;
}

#moduleDocuments_module.cms_list .cms_item_icon_title_row h3.cms_item_title a {
	font-size: 1.2rem;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* submssion forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 1.527rem;
	margin-top: 0px;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}

	#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
		width: 100%;
	}
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
}

/* videos */
#video_module.cms_list .cms_list_item .cms_title h3 a {
	font-size: 1.5rem;
}

#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.35);
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}

#video_module.cms_list_carousel .video-container .video-file-youtube iframe,
#video_module.cms_list_carousel .video-container .video-file .cms_media_container,
#video_module.cms_list_carousel .video-container .video-file video {
	background-color: #000;
	font-family: inherit;
	color: #fff;
}

#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev span,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next span {
	background-color: #fff;
	font-family: inherit;
	color: #000;
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.35);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #002147;
	font-weight: bold;
}

.login-row .create-account-column h2 {
	margin: 0px 0px 5px 0px;
	font-size: 1.3rem;
	line-height: 1.5rem;
}

.login-row .create-account-column .form_button.primary {
	display: inline-block;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
		margin-top: 0px;
	}
}

@media (max-width: 767px) {
	#account_module.form .more_options ul {
		margin-top: 0px;
	}

	.login-row .create-account-column {
		margin-top: 30px;
		border-top: 1px solid #ccc;
		padding-top: 30px;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.module-search input[type="search"] {
	margin-bottom: 0px;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* posts */
.posts-node .posts-comments .required_information {
	color: #e71321;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
	margin: 1.5rem 0 0 0;
	padding: 1rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #525252;
	margin-top: 20px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	background-color: #fff;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.35);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.right-nav-col .box.posts-blocks.posts-blocks-rss-feeds a,
.right-nav-col .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
}


.right-nav-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-nav-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-nav-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-nav-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-nav-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-nav-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.right-nav-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 10px 12px 8px !important;
}

.right-nav-col .posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select:last-of-type {
	margin-bottom: 10px;
}

.right-nav-col .posts-blocks {
	border-bottom: 0px;
}

@media (max-width: 767px) {
	.posts-node .ui-form-fields {
		padding: 0;
		width: 100%;
		overflow: auto;
	}

	.posts-node .ui-form-field {
		width: 100%;
		height: auto;
		overflow: auto;
	}

	.posts-node .ui-form-label {
		clear: both;
		padding-right: 0;
		text-align: left;
		width: 100%;
		overflow: auto;
		position: relative;
		left: auto;
		top: auto;
		height: auto;
	}

	.posts-node .ui-form-input {
		clear: both;
		width: 100%;
		overflow: auto;
		position: relative;
	}

	.ui-form-buttons {
		padding: 0 !important;
		width: 100%;
	}

	.ui-form-buttons button {
		text-align: center;
		width: 100%;
	}
}


/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.5rem 0px 0px 0px;
	padding: 1.5rem 0px 0px 0px;
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.collection.collection--files .field--file--title a,
.collection.collection--categories .field--category--title a {
	padding-left: 0px;
	margin-left: 0px;
}

.collection.collection--files .field--file--title,
.collection.collection--files .field--category--title {
	background-position: 0 3px;
}

.box.filter .select2-container-multi,
.box.filter .select2-container {
	width: 100% !important;
}

.box.filter .select2-container-multi .select2-choices,
.box.filter .select2-container .select2-choice {
	box-shadow: none ;
	border-radius: 0px;
	border: 1px solid #cccccc;
	height: auto;
	background: #fff;
}

.box.filter .select2-container .select2-choice {
	padding: 5px 8px;
}

.box.filter .ui-form-buttons {
	padding: 0px;
}

.box.filter .ui-form-field {
	float: none;
	width: 100%;
}

.box.filter .ui-form-buttons {
	height: auto;
	overflow: hidden;
}

.box.filter .ui-form-buttons button {
	width: auto !important;
}

.module-files.controller-pages-files .ui-form-label span {
	font-weight: normal;
}


/***************** Content Boxes ********************/
/****************************************************/
/* news */
#news_module.homepage_contentbox {
	padding: 20px;
	text-align: left;
}

#news_module.homepage_contentbox .cms_title h3,
#news_module.homepage_contentbox .cms_title h3 a {
	font-size: 1rem;
	line-height: 1.2rem;
	margin-bottom: 5px;
	margin-top: 0px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 0.8rem;
	line-height: 1rem;
	margin-bottom: 10px;
	margin-top: 0px;
}

#news_module.homepage_contentbox .cms_list_item {
	border-bottom: 1px solid #d6d6d6;
	margin-bottom: 15px;
	padding-bottom: 5px;
}

.parts-information #news_module.homepage_contentbox a,
.parts-information #news_module.homepage_contentbox a:hover {
	color: #fff;
}

/* testimonials */
#testimonials_module_contentbox.contentbox_item {
	text-align: left;
	padding: 20px;
}

/* videos */
.homepage-featured-video {
	padding: 20px;
}

.homepage-featured-video iframe {
	max-width: 100%;
}

/* form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
	padding: 20px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* events */
#events_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 15px;
}

#events_module.homepage_contentbox td.date {
	width: 80px;
}

#events_module.homepage_contentbox td.title {
	width: calc(100% - 80px);
}

/* posts */
.home-section .collection.posts-collection,
.grid-container .collection.posts-collection {
	padding: 20px;
	text-align: left;
}

/* files */
.home-section .collection.files-collection,
.grid-container .collection.files-collection {
	padding: 20px;
	text-align: left;
}

.home-section .collection.files-collection .field.files-field,
.grid-container .collection.files-collection .field.files-field {
	margin-bottom: 10px;
}

/***************** eCommerce ********************/
/****************************************************/
#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3,
#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 a {
	font-size: 1.125rem;
	line-height: 20px;
	text-decoration: none;
}

#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 a:hover {
	color: #ed1c29;
}

.products_module .item-sale-price,
#products_module.responsive .item-sale-price {
	color: #e71321 !important;
}

#products_module.responsive .items .item-wrapper .item .item-image {
	padding: 0px;
	border: 0px;
}

#product-search-div .search_submit {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/Search-Blue.svg');
	background-size: 20px 17px;
	background-position: center;
	height: 34px;
	float: right;
	width: 45px;
	border-left: 0px;
}

#s-sign-in-form label em,
#s-register label em,
#shipping label em,
#payment label em {
	color: #e71321;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info .add_to_cart_form .denomination-validation-message,
#products_module.responsive.cms_entity .add_to_cart_form .minimum_quantity_label,
#products_module.responsive.cms_entity .add_to_cart_form .maximum_quantity_label {
	color: #e71321;
}

#products_module.cms_list .items .item .item-prices {
	font-weight: bold;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
}

.fly-out-menu-container .fly-out-menu-button {
	border-color: #ccc;
}

#cross_sells .item-wrapper .item,
#products_module.responsive .items.grid-view .item-wrapper .item .item-description {
	text-align: center;
}

#products_module.responsive.cms_entity .related-items-wrapper .item-short-description {
	text-align: center;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item-content .tab-description > p {
	word-break: break-word;
}

@media (max-width: 479px) {
	.form-shipping-info .ui-form-buttons,
	.form-payment-info .ui-form-buttons {
		padding: 0 !important;
	}
}


/*************** Products page tables ****************/
/****************************************************/
/* heading */
.two-columns-with-media *,
.four-columns *,
.three-columns *,
.two-columns *,
.four-columns-logos *,
.four-columns-logos-small * {
	box-sizing: border-box;
}

.two-columns-with-media h4,
.four-columns h4,
.three-columns h4,
.two-columns h4,
.four-columns-logos h4,
.four-columns-logos-small h4 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-family: Poppins, sans-serif;
	color: #005590;
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
}

/* button */
.product-page-button {
	display: inline-block;
}

.product-page-button a {
	transition: transform 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	transform: translateX(0px) translateY(0px);
	background-color: #525252;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.875rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	padding: 10px 15px;
	display: inline-block;
}

.product-page-button:hover a,
.product-page-button a:hover {
	background-color: #ed1c29;
	transform: translateX(5px) translateY(0px);
}

/* all talbe styles */
.two-columns-with-media,
.four-columns,
.three-columns,
.two-columns,
.four-columns-logos,
.four-columns-logos-small {
	width: 100%;
	margin-bottom: 1rem;
}

.two-columns-with-media tr td,
.four-columns tr td,
.three-columns tr td,
.two-columns tr td,
.four-columns-logos tr td,
.four-columns-logos-small tr td {
	padding: 1rem;
}

.two-columns-with-media tr td iframe,
.four-columns tr td iframe,
.three-columns tr td iframe,
.two-columns tr td iframe,
.four-columns-logos tr td iframe,
.four-columns-logos-small tr td iframe {
	width: 100%;
	height: 100%;
	min-height: 16vw;
}

.two-columns-with-media tr td img,
.four-columns tr td img,
.three-columns tr td img,
.two-columns tr td img,
.four-columns-logos tr td img,
.four-columns-logos-small tr td img {
	max-height: 320px;
	max-width: 100%;
	height: auto;
}

.two-columns-with-media tr td p:last-child,
.four-columns tr td p:last-child,
.three-columns tr td p:last-child,
.two-columns tr td p:last-child,
.four-columns-logos tr td p:last-child,
.four-columns-logos-small tr td p:last-child {
	margin-bottom: 0px;
}


@media (max-width: 991px) {
	.two-columns-with-media tr td iframe,
	.four-columns tr td iframe,
	.three-columns tr td iframe,
	.two-columns tr td iframe,
	.four-columns-logos tr td iframe,
	.four-columns-logos-small tr td iframe {
		min-height: 50vw;
	}
}

/* two-columns-with-media */
.two-columns-with-media tr td {
	width: 50%;
}

.two-columns-with-media tr td:first-child {
	padding-left: 0px;
}

.two-columns-with-media tr td:last-child {
	padding-right: 0px;
}

@media (max-width: 991px) {
	.two-columns-with-media,
	.two-columns-with-media tbody,
	.two-columns-with-media tr,
	.two-columns-with-media tr td {
		display: block;
		width: 100%;
	}

	.two-columns-with-media tr td {
		padding-right: 0px;
		padding-left: 0px;
	}

	.two-columns-with-media tr td p {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.two-columns-with-media tr td img {
		max-height: none;
		display: block;
		margin: 0 auto;
	}
}

/* four-columns-logos-small */
.four-columns-logos-small,
.four-columns-logos-small tbody,
.four-columns-logos-small tr {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.four-columns-logos-small td {
	text-align: center;
	width: 25%;
	vertical-align: top;
	display: block;
}

.four-columns-logos-small tr td img {
	max-width: 70%;
}

/* four-columns-logos */
.four-columns-logos,
.four-columns-logos tbody,
.four-columns-logos tr {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.four-columns-logos td {
	text-align: center;
	width: 25%;
	vertical-align: top;
	display: block;
}

@media (max-width: 991px) {
	.four-columns-logos tr td {
		padding: 0.5rem;
	}
}

@media (max-width: 767px) {
	.four-columns-logos-small td,
	.four-columns-logos td {
		width: 50%;
	}
}

/* four-columns, three-columns, two-columns */
.four-columns,
.three-columns,
.two-columns,
.four-columns tbody,
.four-columns tr,
.three-columns tbody,
.three-columns tr,
.two-columns tbody,
.two-columns tr {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.four-columns td {
	width: 25%;
	vertical-align: top;
	display: block;
}

.three-columns td {
	width: 33%;
	vertical-align: top;
	display: block;
}

.two-columns td {
	width: 50%;
	vertical-align: top;
	display: block;
}

@media (max-width: 991px) {
	.four-columns td,
	.three-columns td,
	.two-columns td {
		width: 50%;
	}
}

@media (max-width: 479px) {
	.four-columns td,
	.three-columns td,
	.two-columns td {
		width: 100%;
	}
}


/***************** Custom Landing page **************/
/****************************************************/
.grid-container {
	padding-top: 125px;
}

.grid-container > .contentbox-container {
	grid-gap: 15px 10px;
	margin-bottom: 2rem;
}

.grid-container .slideshow {
	padding-bottom: 0;
}

.grid-container .content-box.only-content {
	padding-left: 15px;
	padding-right: 15px;
}

.grid-container .spotlight-image-holder.education1 {
	width: 100%;
}

.grid-container .image-on-the-side-container {
	display: flex;
	align-items: center;
}

.grid-container .spotlight-image-holder.education2 {
	height: 350px;
	width: 50%;
}

.grid-container .spotlight-information {
	width: 50%;
}

.grid-container .image-lift-on-hover {
	align-self: flex-start;
	height: 100%;
}

.grid-container .featured-product {
	width: 100%;
	height: 100%;
	margin-bottom: 0px;
}

.grid-container .featured-product-image {
	flex-grow: 1;
	align-items: center;
	height: 200px;
	max-height: 200px;
}

.grid-container .featured-product-image img {
	margin: 0 auto;
	max-height: 100%;
}

.grid-container .parts-icon {
	margin: 0 auto;
}

.grid-container .parts-header {
	text-align: center;
}

.grid-container .slideshow-button.white-button {
	margin: 0 auto;
	margin-top: 1rem;
	width: 160px;
	display: flex;
}

.grid-container .diamond-and-lines {
	justify-content: center;
}

.grid-container .default-container {
	padding-right: 30px;
	padding-left: 30px;
	display: flex;
	align-items: center;
	height: 100%;
}

.grid-container .default-container.align-right {
	text-align: right;
}

.grid-container .default-container .default-container-block {
	display: block;
	width: 100%;
	text-align: center;
}

.default-image {
	margin-bottom: 5px;
	max-width: 450px;
}

.hide-title .default-image {
	text-align: center;
}

@media (max-width: 991px) {
	.grid-container {
		padding-top: 102px;
	}

	.spotlight-holder.scroll-from-left {
		margin-bottom: 2rem;
	}
}

@media (max-width: 767px) {
	.grid-container {
		padding-top: 80px;
	}
}

@media (max-width: 479px) {
	.grid-container {
		padding-top: 66px;
	}

	.grid-container,
	.grid-container .contentbox-container {
		display: flex !important;
		flex-wrap: wrap !important;
		width: 100%;
	}

	.grid-container .slideshow {
		width: 100%;
	}

	.grid-container .content-box.static {
		width: 100%;
	}

	.grid-container .primary-button {
		margin: 0 auto;
		display: block;
		width: 140px;
	}

	.grid-container .parts-store-container {
		width: 100%;
	}
}
